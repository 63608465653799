/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Menu, Text, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, Title, SeparateLine, SeparateLineWrap } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Zásady ochrany osobních údajů"} description={"Naše Zásady ochrany osobních údajů a informace o Cookies"}>
        <Column className="pt--10" name={"zasady-oddelovaci-cara"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="--l8w" name={"t1br1bps55"}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"paddingRight":1013}} columns={"3"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":100}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17440/e9ebb4764c264607a6b018d205abb799_s=860x_.png"} url={"https://www.facebook.com/profile.php?id=61553456535181"} use={"url"} href={"https://www.facebook.com/profile.php?id=61553456535181"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/17440/e9ebb4764c264607a6b018d205abb799_s=350x_.png 350w, https://cdn.swbpg.com/t/17440/e9ebb4764c264607a6b018d205abb799_s=660x_.png 660w, https://cdn.swbpg.com/t/17440/e9ebb4764c264607a6b018d205abb799_s=860x_.png 860w, https://cdn.swbpg.com/t/17440/e9ebb4764c264607a6b018d205abb799_s=1400x_.png 1400w, https://cdn.swbpg.com/t/17440/e9ebb4764c264607a6b018d205abb799_s=2000x_.png 2000w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":100}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17440/c0c6012424764f6096c6364b287246a3_s=860x_.png"} url={"https://www.instagram.com/kamnarstvi_na_rozkosi/"} use={"url"} href={"https://www.instagram.com/kamnarstvi_na_rozkosi/"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/17440/c0c6012424764f6096c6364b287246a3_s=350x_.png 350w, https://cdn.swbpg.com/t/17440/c0c6012424764f6096c6364b287246a3_s=660x_.png 660w, https://cdn.swbpg.com/t/17440/c0c6012424764f6096c6364b287246a3_s=860x_.png 860w, https://cdn.swbpg.com/t/17440/c0c6012424764f6096c6364b287246a3_s=1400x_.png 1400w, https://cdn.swbpg.com/t/17440/c0c6012424764f6096c6364b287246a3_s=2000x_.png 2000w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--menu" menu={true} name={"zasady-hlavicka"}>
          
          <Menu style={{"justify-content":"space-between"}}>
            
            <ColumnWrapper className="menu-logo-box">
              
              <Text className="text-box ff--2 w--600 swpf--uppercase ls--12" style={{"color":"rgb(0,0,0)"}} content={"<span style=\"font-weight: normal;\">DANIEL VLÁHA +420 776 780 996</span>"}>
              </Text>

              <Subtitle className="subtitle-box ff--3 w--400 swpf--uppercase ls--04 mt--0" content={"KAMNÁŘSTVÍ NA ROZKOŠI"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/nabizime"} style={{"maxWidth":125}} target={""} content={"nabízíme"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/cenik"} style={{"maxWidth":125}} target={""} content={"ceník"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/poradna"} style={{"maxWidth":125}} target={""} content={"poradna"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/galerie"} style={{"maxWidth":125}} target={""} content={"GALERIE"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/aktuality"} style={{"maxWidth":125}} target={""} content={"AKTUALITY"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/o-nas"} style={{"maxWidth":125}} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/kontakt"} style={{"maxWidth":125}} target={""} content={"kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column name={"zasady_cech"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--14" content={"člen Cechu kamnářů České republiky"}>
              </Title>

              <Image className="mt--08" src={"https://cdn.swbpg.com/o/17440/5c02d8768d2342198c276b8957ed27a4.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":60}} srcSet={""}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine name={"zasady-oddelovac-1"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--menu" menu={true} name={"7y2v0z8szeo"}>
          
          <Menu >
            
            <MenuButton >
            </MenuButton>

            <MenuWrapBox className="--style5 --center">
              
              <MenuButtonClose >
              </MenuButtonClose>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/kamna"} target={null} content={"KAMNA"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/sporaky"} target={null} content={"SPORÁKY"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/kombinovana_topidla"} target={null} content={"KOMBINOVANÁ TOPIDLA"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/krby"} target={null} content={"KRBY"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/kominy"} target={null} content={"KOMÍNY"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/speciality"} target={null} content={"SPECIALITY"}>
              </Button>

            </MenuWrapBox>

          </Menu>

        </Column>


        <SeparateLine className="pb--10" name={"tiwr5mjzors"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--left pb--10 pt--10" name={"zasady-zasady"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Zásady ochrany osobních údajů"}>
              </Title>

              <Text className="text-box text-box--justify" style={{"maxWidth":650}} content={"Provozovatel webu <span style=\"font-style: italic; font-weight: bold;\">Daniel Vláha IČ: 25714767 se sídlem Šeřeč 3, 552 03 Česká Skalice</span>, zpracovává v případě vaší poptávky služeb ve smyslu nařízení Evropského parlamentu a Rady (EU) č. 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (obecné nařízení o ochraně osobních údajů) (dříve i dále jen „Nařízení“), osobní údaje nutné ke zpracování poptávky služeb, či vytvoření kalkulace.\n<br><br><span style=\"font-weight: bold;\">JAKÉ SHROMAŽĎUJEME OSOBNÍ ÚDAJE A PROČ JE SHROMAŽĎUJEME\n</span><br><br>KONTAKTNÍ FORMULÁŘE<br><br>1) <span style=\"text-decoration-line: underline;\">Kategorie osobních údajů\n</span><br>Správce zpracovává jen nezbytně nutné údaje, které mu poskytnete v souvislosti s kontaktním formulářem nebo objednávkou.\n\nJedná se o tyto údaje:\n<br>- jméno a příjmení\n<br>- emailová adresa\n<br>- telefonní číslo<br><br><br>2) <span style=\"text-decoration-line: underline;\">Zákonný důvod zpracování\n</span><br>Zákonným důvodem zpracování osobních údajů je:<br>- plnění smlouvy mezi Vámi a správcem podle čl. 6 odst. 1 písm. b) GDPR\n<br>- Váš souhlas se zpracováním pro účely retargetingu podle čl. 6 odst. 1 písm. a)&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; GDPR<br>- oprávněný zájem správce pro analýzu návštěvnosti webových stránek a přímého&nbsp; &nbsp; &nbsp; marketingu podle čl. 6 odst. 1 písm. f) GDPR ve spojení s 7 odst. 3 zákona č.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 480/2004&nbsp; &nbsp; Sb., o některých službách informační společnosti<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={""}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"3) <span style=\"text-decoration-line: underline;\">Účel zpracování osobních údajů </span><br>Vaše osobní údaje, které nám poskytnete zpracováváme za účelem: <br>- vyřízení Vaší poptávky <br>- vyřízení Vaší objednávky vyřízení případné reklamace<br>- zodpovězení Vašich dotazů<br><br>Ze strany správce nedochází k automatizovanému rozhodování ve smyslu čl. 22 GDPR.<br><br>4) <span style=\"text-decoration-line: underline;\">Doba, po kterou osobní údaje uchováváme </span><br>Tyto osobní údaje budou správcem zpracovány po nezbytně nutnou dobu, která se liší účelem zpracování: <br>- osobní údaje k objednávce zpracováváme po dobu 10 let od konce zdaňovacího&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;období, ve kterém byl vystaven účetní doklad <br>- osobní údaje k vyřízení reklamace zpracováváme 5 let ode dne ukončení&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;reklamačního řízení <br>- osobní údaje k vyřízení Vašich dotazů zpracováváme po dobu 2 let pro případné&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;další řešení Vašich problémů <br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"zasady-oddelovac-3"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--menu pb--08 pt--20" menu={true} name={"zasady-zapati"}>
          
          <Menu style={{"justify-content":"space-between"}}>
            
            <ColumnWrapper className="menu-logo-box">
              
              <Text className="text-box ff--2 w--600 swpf--uppercase ls--12" style={{"color":"rgb(0,0,0)"}} content={"<span style=\"font-weight: normal;\">DANIEL VLÁHA +420 776 780 996</span>"}>
              </Text>

              <Subtitle className="subtitle-box ff--3 w--400 swpf--uppercase ls--04 mt--0" content={"kamnářství na rozkoši"}>
              </Subtitle>

              <Subtitle className="subtitle-box ff--3 fs--12 mt--06" content={"člen Cechu kamnářů České republiky"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton className="--hvr2 --filling2">
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--sbtn1 btn-box--style2 fs--16 w--600 btn-box--invert lh--1" href={"/nabizime"} target={""} content={"NABÍZÍME"}>
                </Button>

                <Button className="btn-box btn-box--style2 fs--16 w--600 btn-box--invert lh--1" href={"/kontakt"} target={""} content={"KONTAKT"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="--center" name={"zasady-mesta"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--center ff--3 fs--14 swpf--uppercase" content={"NÁCHOD - ČESKÁ SKALICE - NOVÉ MĚSTO NAD METUJÍ - ČERVENÝ KOSTELEC - BROUMOV - TRUTNOV - DOBRUŠKA - OPOČNO - RYCHNOV NAD KNĚŽNOU - POLICE NAD METUJÍ"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--10" name={"zasady-oddelovac-4"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="pt--0" style={{"paddingBottom":2,"backgroundColor":"var(--color-variable-1)"}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"zasady-zapati-text"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 fs--12" href={"/zasady-ochrany-osobnich-udaju"} target={null} content={"ZÁSADY OCHRANY OSOBNÍCH ÚDAJŮ A POLITIKA COOKIES"}>
              </Button>

              <Text className="text-box ff--3 fs--14 mt--06" style={{"color":"rgb(0, 0, 0)"}} content={"Tento web používá k poskytování služeb soubory Cookie. Přečtěte si podrobné informace o souborech Cookie a naše Zásady ochrany osobních údajů před dalším užíváním těchto stránek. Veškeré zde zveřejněné informace a fotografie jsou majetkem Kamnářství na Rozkoši. Jejich šíření je bez souhlasu majitele zakázáno! Děkujeme, že respektujete autorská práva.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}